export { matchers } from './client-matchers.js';

export const components = [
	() => import("../../src/routes/__layout.svelte"),
	() => import("../runtime/components/error.svelte"),
	() => import("../../src/routes/app/__layout.svelte"),
	() => import("../../src/routes/app/address/[address].svelte"),
	() => import("../../src/routes/app/index.svelte"),
	() => import("../../src/routes/app/name/[name].svelte"),
	() => import("../../src/routes/index.svelte")
];

export const dictionary = {
	"": [[0, 6], [1]],
	"app": [[0, 2, 4], [1]],
	"app/address/[address]": [[0, 2, 3], [1]],
	"app/name/[name]": [[0, 2, 5], [1]]
};